import React from 'react';
import styled from 'styled-components';
import { mobileThresholdPixels } from '../../home/v3/styledComponents';
import mask1 from '../../../assets/covid/mask1.png';
import mask2 from '../../../assets/covid/mask2.png';
import MaskProduct from './MaskProduct';
import MaskFeatures from './MaskFeatures';

const Container = styled.div`
  max-width: 880px;
  margin: 60px auto 150px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100vw - 2 * 22px);
    margin: 25px 22px 70px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

const Img = styled.img`
  ${(props) => props.marginTop && 'margin-top: 22px;'}

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    ${(props) => props.marginTop && 'margin-top: 15px;'}
  }
`;

const renderPhotos = () => (
  <div>
    <Img src={mask1} alt="Masques en tissu" />
    <Img marginTop src={mask2} alt="Masques en tissu" />
  </div>
);

const Masks = () => (
  <Container>
    <Row>
      {renderPhotos()}
      <MaskProduct />
    </Row>
    <MaskFeatures />
  </Container>
);

export default Masks;

import React from 'react';
import styled from 'styled-components';
import Layout from '../../layouts/index';
import { Container, Content, colors } from '../../components/home/v3/styledComponents';
import MenuBar from '../../components/home/MenuBar';
import Footer from '../../components/home/v3/Footer';
import Masks from '../../components/order/masks/Masks';
import BenefitsBanner from '../../components/home/v3/BenefitsBanner';

const StyledContainer = styled(Container)`
  background-color: ${colors.white};
`;

const MasksPage = () => (
  <Layout routeSlug="Masks">
    <StyledContainer>
      <MenuBar showGiftBanner={false} />
      <Content hasNoBanner>
        <Masks />
      </Content>
      <BenefitsBanner backgroundColor="linen" color="navy" />
      <Footer />
    </StyledContainer>
  </Layout>
);

export default MasksPage;

import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

const customStyles = {
  control: (base) => ({
    ...base,
    borderColor: ({ theme }) => theme.colors.navy,
    height: '40px',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontWeight: '300',
    borderRadius: '0',
    paddingLeft: '7px',
  }),
};

const Select = ({
  value, onChange, options, isSearchable,
}) => (
  <ReactSelect
    value={value}
    onChange={onChange}
    options={options}
    styles={customStyles}
    isSearchable={isSearchable}
    theme={(theme) => ({ ...theme })}
  />
);

Select.propTypes = {
  value: PropTypes.shape({}),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isSearchable: PropTypes.bool,
};

Select.defaultProps = {
  value: undefined,
  onChange() { },
  isSearchable: true,
};

export default Select;
